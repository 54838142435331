.RightPanel{
  display: flex;
  flex-direction: column;
  background-color: var(--right-side-light);
  width: 30vw;
  overflow: hidden;
}

/* Project name at top */
.RightPanel .header {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

a.headerName {
  /* Ignore default a tag styles */
  text-decoration: inherit;
  color: inherit;
}

a.headerName > h1 {
  background: transparent;
  position: relative;
  color: white;
  font-size: x-large;
}

a.aboutLink {
  text-decoration: underline;
  color: white;

  font-size: medium;
}

/* The rest */

.FilterPart{
  overflow: hidden auto;
  flex: 1 0 auto;
}

.DetailPart{
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}