/*The root of the filter panel*/
.FilterPanel {
  padding: 0 1em;
  height: inherit;
}

.FilterNameTag {
  flex-shrink: 0;
  font-size:medium;
  color: white;
  width: 110px;
  margin: 0.5em 0px 0.5em 0px;
}

.FilterNameTag.withInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Each rows root*/
.FilterRowDiv {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  text-align: left;
  padding-bottom: 0.5em;
  min-height: 3px;
}

.DropDown {
  flex-grow: 1;
  padding-left: 1em;
}

.optionValueCount {
  color: rgba(0, 0, 0, 0.6);
  font-size: 85%;
}
.DropDown__value-container .optionValueCount {
  display: none;
}


.SatCountText {
  font-style: italic;
  color: white;
  margin: 1em 0em 1em 0em;
}

.infoIcon {
  cursor: pointer;
  color: #fddd02;
  margin-left: 8px;
  vertical-align: middle;

  transition: filter 200ms ease;
}

.infoIcon:hover {
  /* SVG drop shadow from: https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3 */
  --shadow: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  -webkit-filter: var(--shadow);
  filter: var(--shadow);
}

input[type="checkbox"] {
  vertical-align: middle;
}

/*input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


.checkbox {
  display: inline-block; 
  vertical-align: middle;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 4px #ddd solid;
  border-radius: 1em;
  margin-right: 4px;
}

.checkbox:hover {
  background-color: rgb(129, 123, 123);
}

.checkbox--active {
  border-color: rgb(211, 219, 211);
  background: rgb(46, 53, 46)
} */