.MainDetailPanel{
  display: flex;
  flex-direction: column;
  min-height: 0;
  text-align: left;
}

.DetailDiv{
  overflow: hidden auto;
  padding: 0.5em 0;
  line-height: 130%;
  color: white;
}

.DetailButton{
  width: inherit;
  cursor: pointer;
  height: 40px;
  border: none;
  background-color: var(--right-side-dark);
  color: var(--silver);
  width: 100%;
  padding: 1em;

  border: 2px solid transparent;
}

.DetailButton:hover {
  color: white;
}

.DetailTitle {
  padding: 0.2em 16px;
  font-size: 120%;
  font-weight: bold;
  text-align: center;
}

.DetailRow{
  padding: 0.2em 16px;
  display: flex;
}

.DetailRow.spacer{
  margin-top: 0.8em;
}

.DetailRow.subtitle {
  padding-top: 0;
  text-align: center;
  font-style: italic;
  font-weight: lighter;
  font-size: 90%;
}

.DetailRowLabel{
  flex: 0 0 90px;
  padding-right: 8px;
  font-size: 90%;
  color: white;
  opacity: 0.7;
}

.DetailRowValue{
  flex-grow: 1;
  color: white;
}

.DetailRow .sourceLink {
  margin-right: 8px;
  display: inline-block;
  font-size: 90%;
}

.DetailRow .comments {
  font-size: 90%;
  font-style: italic;
}

.NoSelection {
  margin: 1em 0;
  font-style: italic;
  color: white;
  opacity: 0.8;
  text-align: center;
}

.help {
  cursor: help;
}

.MainDetailPanel a {
  color: lightblue
}
