.OrbitExplainerSwiper {
  width: 100%;
  height: 100%;
}

.OrbitExplainerSlide {
  /* Center content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OrbitExplainerSlide h1 {
  grid-column: 1/3;
  
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.OrbitExplainerSlide p {
  margin: 0 2em;
  max-width: 700px;
}

.OrbitExplainerSlide img {
  height: 100%;
  object-fit: scale-down;
}