.About {
  --nav-base: var(--wet-asphalt);
  --nav-selected: var(--midnight-blue);

  height: 100%;
  width: 100%;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  color: white;
  background-color: var(--midnight-blue);
}

.About a {
  /* Links must stand out from blue background */
  color: lightcoral;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;  
}

.nav.nav-horizontal {
  flex-direction: row;
}

.nav.nav-vertical {
  flex-direction: column;
}

.nav.nav-shadow {
  z-index: 1;
}
.nav.nav-horizontal.nav-shadow {
  box-shadow: 0 5px 10px -10px black;
}
.nav.nav-vertical.nav-shadow {
  box-shadow: 5px 0 10px -10px black;
}

.nav a {
  text-decoration: inherit;
  color: white;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: var(--nav-base);
  border-bottom: 2px solid transparent;
}

.nav a.selected {
  background-color: var(--nav-selected);
  border-bottom-color: white;
}

.nav a:hover {
  background-color: var(--nav-selected);
}

.nav.nav-vertical a {
  padding: 0.5rem 2rem;
  
  border-bottom: 0;
  border-right: 2px solid transparent;
}

.nav.nav-vertical a.selected {
  border-color: white;
}

.About .content {
  flex-grow: 1;
  padding: 2rem;
}

/* Individual pages */
.DemoVideo {
  text-align: center;
}

.ProjectOverview {
  width: fit-content;
  min-width: 50%;
  max-width: 800px;
  margin: 0 auto;
}

.TeamMembers {
  display: flex;
  flex-direction: row;

  --nav-selected: var(--blue-gray-700);
  --nav-base: var(--blue-gray-600);
}
.TeamMembers .content {
  flex-grow: 1;
  padding: 1rem;
  background-color: var(--nav-selected);
  display: flex;
  flex-direction: column;

  overflow-y: hidden;
}
.TeamMembers .content h2 {
  margin-top: 0;
  text-align: center;
}
.TeamMembers .content a.social {
  text-decoration: inherit;
  color: white;
  margin: 0 5px;
}
.TeamMembers .memberInfo {
  flex-grow: 1;

  /* Prevent text from rubbing up against scroll bar */
  padding: 0 10px;

  /* Prevent random non-scrollable horizontal scroll bar */
  overflow-x: hidden;

  /* Force scroll instead of grow */
  height: 0;
  min-height: fit-content;
  overflow-y: auto;

  line-height: 150%;
}
.TeamMembers .memberInfo img {
  float: left;
  min-width: 100px;
  max-width: 50%;
  max-height: 100%;
  margin-right: 1em;
}