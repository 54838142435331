.Legend {
  pointer-events: none;
  position: absolute;
  top: 1em;
  right: 1em;
  padding: .33em .5em;
  font-size: 80%;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
}

.Legend .title {
  text-align: left;
  font-weight: bold;
  margin-bottom: .66em;
}

.Legend .entry {
  margin: .33em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Legend .entryColor {
  width: 32px;
  height: 4px;
  margin-right: .5em;
}

.SwitchMode {
  pointer-events: none;
  position: absolute;
  top: 1em;
  left: 1em;
  color: white;
  display: flex;
}

.SwitchMode .SwitchLink {
  width: 70px;
  padding: 0.5em 1em;
  margin-right: 1em;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  color: white;
  background-color: #3e5958;
  font-weight: lighter;
  opacity: 0.66;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.SwitchMode .SwitchLink.selected {
  opacity: 1;
  border-color: white;
}

.SwitchMode .SwitchLink .icon {
  font-size: 150%;
  margin-bottom: 3px;
}

.WarnShowingLimited {
  pointer-events: none;
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.5em 1em;
  max-width: 380px;
  display: flex;
  align-items: center;

  background-color: var(--warning-red);
  font-size: 80%;
  color: white;
  text-align: left;
}

.WarnShowingLimited > svg {
  flex-shrink: 0;
}

.WarnShowingLimited > span {
  margin: 0 1em;
}

.WarnShowingLimited .closeButton {
  pointer-events: initial;
  margin: -7px;
  padding: 7px;
  cursor: pointer;
  font-size: 150%;
}
