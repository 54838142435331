.ViewContainer {
  --nav-height: 50px;
}

/* Wipe control styles */
.wipeContainer {
  height: 100%;
  overflow-y: hidden;
}

.wipe {
  overflow: hidden;
  height: 100%;
  transition: height 500ms ease;
}

.wipe.hidden {
  height: 0;
}


/* Top section styles */
.optionBlockContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 5fr 5fr var(--nav-height);

  color: white;
  background-color: var(--nav-light);
}

.optionBlockContainer.uncollapsible {
  grid-template-rows: 1fr 1fr;
}

.optionBlock {
  /* Ignore default a tag styles */
  text-decoration: inherit;
  color: inherit;

  /* Allow for overlay of ::before background image */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.optionBlock::before {
  content: "";
  width: 100%;
  height: 100%;
  
  /* Background image is set in separate class */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 25%;

  transition: opacity 250ms ease;

  /* Overlay on top of actual content */
  grid-column: 1;
  grid-row: 1;
}

.optionBlock:hover::before {
  opacity: 15%;
}

.optionBlockContainer.hidden .optionBlock::before {
  opacity: 0;
}

/* TODO: should this be styled too in grid view? */
/* .optionBlock.selected {
} */

/* Selector covers both actual options/tabs as well as the expand/collapse buttons */
.optionBlockContainer > *, .optionTabContainer > * {
  /* Match fade out of background image */
  transition: background-color 250ms ease, border-bottom-color 250ms ease;
  cursor: pointer;
}
.optionBlockContainer > :hover, .optionTabContainer > :hover {
  color: white;
  background-color: var(--nav-dark);
}

.optionContainer {
  padding: 0 2rem;

  /* Overlay on top of ::before background image */
  grid-column: 1;
  grid-row: 1;

  /* Center contents */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.optionTitle {
  font-size: large;
  display: flex;
}

.optionBlock .optionTitle .icon {
  margin-right: 10px;
}

.optionDescription {
  /* color: gray; */
  font-size: small;
}

.wipeUpButton {
  grid-column: 1/3;
  grid-row: 3;

  display: flex;
  align-items: center;
  justify-content: center;
}


/* Bottom section styles */
.optionTabsAndViewContainer {
  display: grid;
  grid-template-rows: var(--nav-height) auto;
}

.optionTabContainer {
  display: flex;
  justify-content: space-evenly;
  
  background-color: var(--nav-light);
}

.optionTab {
  /* Ignore default a tag styles */
  text-decoration: inherit;
  
  flex-grow: 1;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: rgba(255, 255, 255, 0.66);
}

.optionTab .icon {
  margin-right: 10px;
}

.optionTab:hover {
  color: white;
  background-color: var(--nav-dark);
  border-bottom-color: var(--nav-dark);
}

/* Highlight Selected */
.optionTab, .optionBlock {
  border-bottom: 2px solid transparent;
}

.optionTab.selected, .optionBlock.selected {
  color: white;
  background-color: var(--nav-dark);
  border-bottom: 2px solid white;
}


/* Background images */
.__Orbits_img::before {
  background-image: url(../assets/ModeOrbits.png);
}

.__GroundTracks_img::before {
  background-image: url(../assets/ModeGroundTracks.png);
}

.__Origins_img::before {
  background-image: url(../assets/ModeOrigin.png);
}

.__Decay_img::before {
  background-image: url(../assets/ModeDecay.png);
}