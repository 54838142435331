.WorldMap {
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative; /* To anchor the legend at the correct container. */
  background-color: black;
}

.worldMapSVG {
  width: 100%;
  height: 100%;  
  padding: 0;
  background-color: rgb(218, 238, 255);
}

#tooltip {
  position: fixed;
  color: white;
  background-color: rgba(128, 128, 128, 0.637);
  color: "dark";
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;

  /* Begin invisible (will be overriden later) */
  opacity: 0;
}

.assoMapContainer {
  width: 95%;
  position: absolute;
  bottom: 0.3em;
  right: 2.5%;
  padding: 0.2em;  
  background-color: rgba(173, 216, 230, 0.3);
  border-top: solid 1px rgb(117, 117, 117); 
}

.assoLabel {
  pointer-events: none;
}


